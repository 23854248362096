import AnimatedLetters from "../AnimatedLetters"
import React from "react"
import "./index.scss"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faJsSquare,
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faReact,
} from "@fortawesome/free-brands-svg-icons"
import Loader from "react-loaders"
import Resume from "../../assets/pdfs/Resume2023Final.pdf"

const About = () => {
  const [letterClass, setLetterClass] = useState("text-animate")

  useEffect(() => {
    setTimeout(() => {
      return setLetterClass("text-animate-hover")
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={["A", "b", "o", "u", "t", " ", "m", "e"]}
              idx={15}
            />
          </h1>
          <p>
            I'm a young determined full stack developer eager to work with
            knowledgeable peers and the latest technologies
          </p>
          <p>
            I'm dependable, naturally open-minded, and quietly confident in my
            ability to take on any task that is put in front of me
          </p>
          <p>
            If I had to define myself in one sentence that would be a friendly
            energetic person, a huge sports fan, car enthusiast, and a tech
            lover!
          </p>
          <a
            className="flat-button"
            href={Resume}
            target="_blank"
            rel="noreferrer"
          >
            Resume
          </a>
        </div>

        <div className="stage-cube-cont">
          <div className="cube-spinner">
            <div className="face1">
              <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
